import { AppProps, NextWebVitalsMetric } from 'next/app';
import { GlobalStyles } from 'twin.macro';
import { Layout } from '../components/Layout';
import Head from 'next/head';
import ErrorBoundary from '../components/Layout/errorBoundary';
import GoogleAnalytics from '../lib/googleAnalytics';

const App = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <ErrorBoundary>
        <GoogleAnalytics />
        <GlobalStyles />
        <Head>
          <meta name="viewport" content="initial-scale=1.0, width=device-width, shrink-to-fit=no" />
          <meta name="apple-mobile-web-app-capabale" content="yes" />
          <meta name="format-detection" content="telephone=no" />
        </Head>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ErrorBoundary>
    </>
  );
};

export const reportWebVitals = (metric: NextWebVitalsMetric) => {
  console.log(metric);
}

export default App;
